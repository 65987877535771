var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
    ((_vm.$loggedUser ? _vm.$loggedUser.SelectedRole == 'Client' : true) ||
      (_vm.$store.state.loggedUser
        ? _vm.$store.state.loggedUser.SelectedRole == 'Client'
        : true)) &&
    _vm.profileFillingRate
      ? _vm.$t('client.menu.profileFillingRate') +
        ': \n' +
        _vm.$t('client.menu.profilePersonalData') +
        _vm.profileFillingRate.PersonalProfile +
        '%\n' +
        _vm.$t('client.menu.profileMedicalData') +
        _vm.profileFillingRate.MedicalProfile +
        '%'
      : ''
  ),expression:"\n    (($loggedUser ? $loggedUser.SelectedRole == 'Client' : true) ||\n      ($store.state.loggedUser\n        ? $store.state.loggedUser.SelectedRole == 'Client'\n        : true)) &&\n    profileFillingRate\n      ? $t('client.menu.profileFillingRate') +\n        ': \\n' +\n        $t('client.menu.profilePersonalData') +\n        profileFillingRate.PersonalProfile +\n        '%\\n' +\n        $t('client.menu.profileMedicalData') +\n        profileFillingRate.MedicalProfile +\n        '%'\n      : ''\n  ",modifiers:{"hover":true}}],staticClass:"eh-web-app-header-profile-icon",staticStyle:{"width":"fit-content"}},[_c('router-link',{staticStyle:{"position":"relative","z-index":"1"},attrs:{"to":"Profile"}},[(_vm.profileImg)?_c('img',{staticClass:"client-profil-img",attrs:{"src":_vm.profileImg}}):_c('div',{staticClass:"client-profil-img empty-img"},[_c('b-spinner',{staticClass:"profile-img-loading"})],1),(
        (_vm.$loggedUser ? _vm.$loggedUser.SelectedRole == 'Client' : true) ||
        (_vm.$store.state.loggedUser
          ? _vm.$store.state.loggedUser.SelectedRole == 'Client'
          : true)
      )?_c('profile-progress-chart',{attrs:{"profileFillingRate":_vm.profileFillingRate,"signedProfilePicture":_vm.signedProfilePicture}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }