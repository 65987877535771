<template>
  <div
    style="
      position: absolute;
      margin-top: -90px;
      margin-left: -10px;
      z-index: -10;
    "
  >
    <ejs-accumulationchart
      id="pie-filling-rate"
      v-if="profileFillingRateChartData && profileFillingRateChartData.length > 0"
      ref="pieFillingRate"
      align="center"
      height="100px"
      width="100px"
      background="transparent"
    >
      <e-accumulation-series-collection>
        <e-accumulation-series
          :dataSource="profileFillingRateChartData"
          :pointColorMapping="'fill'"
          radius="120%"
          xName="x"
          yName="y"
          innerRadius="20%"
        ></e-accumulation-series>
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </div>
</template>
<script>
import Vue from "vue";
import { PieSeries, AccumulationChartPlugin } from "@syncfusion/ej2-vue-charts";

Vue.use(AccumulationChartPlugin);
export default {
  name: "ProfilePictureWithChart",
  props: {
    profileFillingRate: Object,
    signedProfilePicture: String,
  },
  data() {
    return {
      profileFillingRateChartData: null,
    };
  },
  watch: {
    profileFillingRate() {
      this.setChartData();
    },
    signedProfilePicture(input) {
      this.profileImg = input;
    },
  },
  provide: {
    accumulationchart: [PieSeries],
  },
  methods: {
    setChartData() {
      //TODO: kiemelés metódusba (getProfile metós használja majd együtt)
      //lenullázzuk a profil kitöltöttség jelző tárolót
      this.profileFillingRateChartData = null;
      //feltöltjük az új adatokkal
      this.profileFillingRateChartData = [
        {
          x: "medicalFilledRate",
          y: this.profileFillingRate
            ? this.profileFillingRate.MedicalProfile
            : 0,
          fill: "var(--eh-primary-5)",
        },
        {
          x: "medicalEmptyRate",
          y:
            100 -
            (this.profileFillingRate
              ? this.profileFillingRate.MedicalProfile
              : 0),
          fill: "var(--eh-primary-3)",
        },
        {
          x: "personalFilledRate",
          y:
            100 -
            (this.profileFillingRate
              ? this.profileFillingRate.PersonalProfile
              : 0),
          fill: "var(--eh-primary-3)",
        },
        {
          x: "personalEmptyRate",
          y: this.profileFillingRate
            ? this.profileFillingRate.PersonalProfile
            : 0,
          fill: "var(--eh-secondary-4)",
        },
      ];
      //van kitöltöttség jelző chart?
      if (this.$refs.pieFillingRate) {
        //igen, átadjuk a chartnak közvetlenül az új értékeit
        this.$refs.pieFillingRate.ej2Instances.series[0].dataSource[0].y = this.profileFillingRateChartData[0].y;
        this.$refs.pieFillingRate.ej2Instances.series[0].dataSource[1].y = this.profileFillingRateChartData[1].y;
        this.$refs.pieFillingRate.ej2Instances.series[0].dataSource[2].y = this.profileFillingRateChartData[2].y;
        this.$refs.pieFillingRate.ej2Instances.series[0].dataSource[3].y = this.profileFillingRateChartData[3].y;
        //frissítésre kényszerítjük
        this.$refs.pieFillingRate.ej2Instances.refresh();
      }
    },
  },
  mounted() {
    console.log("RENDER_PROFILE_CHART");
    this.setChartData();
  },
};
</script>
