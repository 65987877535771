<template>
  <!-- TODO: vue.prototype és store együttes szűrés, amíg a rendszerek átállnak az új rendszer szerkezetre-->
  <div
    class="eh-web-app-header-profile-icon"
    style="width: fit-content"
    v-b-tooltip.hover="
      (($loggedUser ? $loggedUser.SelectedRole == 'Client' : true) ||
        ($store.state.loggedUser
          ? $store.state.loggedUser.SelectedRole == 'Client'
          : true)) &&
      profileFillingRate
        ? $t('client.menu.profileFillingRate') +
          ': \n' +
          $t('client.menu.profilePersonalData') +
          profileFillingRate.PersonalProfile +
          '%\n' +
          $t('client.menu.profileMedicalData') +
          profileFillingRate.MedicalProfile +
          '%'
        : ''
    "
  >
    <router-link style="position: relative; z-index: 1" to="Profile">
      <!-- <img id="client-profil-icon" :src="profileImg" /> -->
      <img v-if="profileImg" class="client-profil-img" :src="profileImg" />
      <div v-else class="client-profil-img empty-img">
        <b-spinner class="profile-img-loading" />
      </div>
      <profile-progress-chart
        v-if="
          ($loggedUser ? $loggedUser.SelectedRole == 'Client' : true) ||
          ($store.state.loggedUser
            ? $store.state.loggedUser.SelectedRole == 'Client'
            : true)
        "
        :profileFillingRate="profileFillingRate"
        :signedProfilePicture="signedProfilePicture"
      />
    </router-link>
  </div>
</template>
<script>
import ProfileProgressChart from "./ProfileProgressChart.vue";

export default {
  components: { ProfileProgressChart },
  name: "ProfilePicture",
  props: {
    profileImg: String,
    profileFillingRate: Object,
    signedProfilePicture: String,
    //showProfileProgressChart: Boolean, TODO: kell ilyen kapcsoló? Vagy csak Clientnél lesz töltés szint?
  },
};
</script>
<style scoped>
.client-profil-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: relative;
  z-index: 10;
}

.client-profil-img.empty-img {
  background: white;
  text-align: center;
}

.client-profil-img.empty-img .profile-img-loading {
  margin: 25px 0px;
}
</style>
